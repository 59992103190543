import { useState } from "react";
import { QrReader } from "react-qr-reader";

const QRScan = (props) => {
  return (
    <>
      <div className="absolute top-0 left-0 w-screen h-screen bg-[#000000b3]"></div>
      <div className="absolute py-20 z-50  top-0 left-0 w-screen h-screen flex flex-col justify-between items-center">
        <div className="flex flex-col space-y-2">
          <div className=" text-white text-center font-bold text-2xl uppercase">
            {props.gate} {props.event}
          </div>
          <div className=" text-white text-center font-semibold">
            Please scan QR Code
          </div>
        </div>
        <div className=" w-full flex justify-center items-center ">
          <button
            onClick={() => props.onClose()}
            className="min-w-[160px] px-10 py-2 bg-red-500 rounded-lg text-white font-bold"
          >
            CLOSE
          </button>
        </div>
      </div>
      <div className="fixed top-1/2 -translate-y-1/2 md:-translate-x-0 left-0 w-screen h-auto md:pb-0">
        <QrReader
          constraints={{
            focusMode: "continuous",
            facingMode: 'environment',
            advanced: [{ zoom: 2 }],
            fps: 100,
            qrbox: 200,
            aspectRatio: 1,
          }}
          onResult={(result, error) => {
            if (!!result) {
              let res = result?.text;
              props.onSuccess(res);
            }

            if (!!error) {
              //props.onFailed(error);
            }
          }}
        />
      </div>
    </>
  );
};

export default QRScan;
