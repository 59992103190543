import { useEffect, useState } from "react";
import QRScan from "../../components/qr_scan";
import { useQuery } from "../../hook/useQuery";
import axios from "axios";
import CONFIG from "../../constanta/config";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

const PageCheckout = () => {
  const query = useQuery();
  const [state, setState] = useState("idle");
  const [showQR, setShowQR] = useState(false);
  const [loading, setLoading] = useState(false);
  const [infoAction, setInfoAction] = useState(false);
  const [user, setUser] = useState(null);
  const [sukses, setSukses] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (Cookies.get("token") == null || Cookies.get("token") === "") {
      navigate("/");
    } 
  }, []);

  const handleSuccess = (qr) => {
    checkDataUser(qr);
  };

  const handleFailed = () => {
    setShowQR(false);
    setUser(null);
    setState("failed");
  };

  const handleCloseScan = () => {
    setShowQR(false);
    setUser(null);
    setState("idle");
  };

  const handleOpenScan = () => {
    setShowQR(true);
    setInfoAction(false);
    setUser(null);
    setState("process");
  };

  const checkDataUser = (qr) => {
    setShowQR(false);
    setLoading(true);
    axios
      .get(CONFIG.URL + "/user/" + qr, {
        headers: {
          token: Cookies.get("token"),
        },
      })
      .then(function (response) {
        if (response?.data.status === "SUCCESS") {
          let event = response.data?.data[0]?.event.find(
            (item) => item.event === query.get("event")
          );
          if (event) {
            setUser(response.data?.data[0]);

            if (event.checkin_time !== "0000-00-00 00:00:00") {
              setState("success");
            } else {
              if (event.checkout_time === "0000-00-00 00:00:00") {
                setState("checkout");
              } else {
                setState("exist");
              }
            }
          } else {
            setState("failed");
          }
        } else {
          setState("failed");
        }
        setLoading(false);
      })
      .catch(function (error) {
        handleOpenScan();
        setLoading(false);
      });
  };

  const handleCheckout = () => {
    setSukses(false);
    setState("process");
    setLoading(true);

    const payload = {
      qr_code: user?.qr_code,
      event: query.get("event"),
    };

    var form_data = new FormData();

    for (var key in payload) {
      form_data.append(key, payload[key]);
    }

    axios
      .post(CONFIG.URL + "/event/checkout", form_data, {
        headers: {
          token: Cookies.get("token"),
        },
      })
      .then(function (response) {
        setInfoAction(true);
        setLoading(false);
        if (response?.data?.status === "SUCCESS") {
          setSukses(true);
        } else {
          setSukses(false);
        }
      })
      .catch(function (error) {
        setLoading("false");
        setSukses(false);
      });
  };

  const handleActionFailed = () => {
    setState("success");
    setInfoAction(false);
  };

  return (
    <>
      {loading && (
        <>
          <div className="flex flex-col absolute top-0 left-0 w-screen h-screen bg-[#000000b3] text-white justify-center items-center font-semibold text-xl">
            Loading...
          </div>
        </>
      )}

      {infoAction && (
        <>
          <div className="flex flex-col absolute top-0 left-0 w-screen h-screen bg-[#000000b3] text-white justify-center items-center font-semibold text-xl">
            {sukses ? (
              <>
                <span className="text-green-500 font-bold">
                  Check Out Success
                </span>
                <button
                  onClick={() => handleOpenScan()}
                  className="mt-5 min-w-[160px] px-10 py-2 bg-green-500 rounded-lg text-white font-bold"
                >
                  OK
                </button>
              </>
            ) : (
              <>
                <span className="text-red-500 font-bold">Check Out Failed</span>
                <button
                  onClick={() => handleActionFailed()}
                  className="mt-5 min-w-[160px] px-10 py-2 bg-red-500 rounded-lg text-white font-bold"
                >
                  OK
                </button>
              </>
            )}
          </div>
        </>
      )}

      {showQR && (
        <QRScan
          onClose={() => handleCloseScan()}
          onSuccess={(data) => handleSuccess(data)}
          onFailed={() => handleFailed()}
          gate="CHECK OUT"
          event={query.get("event")}
        />
      )}

      <div
        className="w-full h-screen flex flex-col justify-center items-center bg-gray-200 px-5"
        style={{
          background: `url('https://eventqiu.com/assets/bg_quiz.jpg') no-repeat center center fixed`,
          backgroundSize: "cover",
        }}
      >
        {state === "idle" && (
          <div className="flex flex-col justify-center items-center pb-20">
            <div className="text-white font-semibold mb-2">CHECK OUT</div>
            <div className="text-white font-bold mb-10 text-x uppercase">
              {query.get("event")}
            </div>

            <button
              onClick={() => handleOpenScan()}
              className="min-w-[160px] px-10 py-2 bg-blue-500 rounded-lg text-white font-bold"
            >
              SCAN
            </button>
            <a
              href={`/home?event=${query.get("event")}`}
              className="min-w-[160px] mt-4 px-10 py-2 bg-red-500 rounded-lg text-white font-bold"
            >
              GO HOME
            </a>
          </div>
        )}

        {state === "success" && (
          <div className="flex flex-col justify-center items-center pb-20">
            <div className="text-white font-semibold mb-2">CHECK OUT</div>
            <div className="text-white font-bold mb-5 text-x uppercase">
              {query.get("event")}
            </div>

            <div className="text-white font-bold mb-6 text-xl bg-green-500 px-10 py-12 rounded-md">
              Allowed to Checkout
            </div>

            <div className="text-white font-bold mb-0 text-lg text-center">
              {user?.user_type}
            </div>

            <div className="text-white font-bold mb-6 text-2xl text-center">
              {user?.name}
            </div>

            <button
              onClick={() => handleCheckout()}
              className="min-w-[160px]  px-10 py-2 bg-blue-500 rounded-lg text-white font-bold"
            >
              CHECK OUT
            </button>

            <button
              onClick={() => handleOpenScan()}
              className="mt-20 min-w-[160px] px-10 py-2 bg-red-500 rounded-lg text-white font-bold"
            >
              CANCEL
            </button>
          </div>
        )}

        {state === "failed" && (
          <div className="flex flex-col justify-center items-center pb-20">
            <div className="text-white font-semibold mb-2">CHECK OUT</div>
            <div className="text-white font-bold mb-10 text-x uppercase">
              {query.get("event")}
            </div>

            <div className="text-white font-bold mb-10 text-xl bg-red-500 px-10 py-12 rounded-md">
              Not Allowed to Checkout
            </div>

            <div className="text-white font-bold mb-0 text-lg text-center">
              {user?.user_type}
            </div>
            <div className="text-white font-bold mb-6 text-2xl text-center">
              {user?.name}
            </div>

            <button
              onClick={() => handleOpenScan()}
              className="min-w-[160px] px-10 py-2 bg-blue-500 rounded-lg text-white font-bold"
            >
              OK
            </button>
          </div>
        )}

        {state === "exist" && (
          <div className="flex flex-col justify-center items-center pb-20">
            <div className="text-white font-semibold mb-2">CHECK OUT</div>
            <div className="text-white font-bold mb-10 text-x uppercase">
              {query.get("event")}
            </div>

            <div className="text-white font-bold mb-10 text-xl bg-red-500 px-10 py-12 rounded-md">
              Already Check Out
            </div>

            <div className="text-white font-bold mb-0 text-lg text-center">
              {user?.user_type}
            </div>
            <div className="text-white font-bold mb-6 text-2xl text-center">
              {user?.name}
            </div>

            <button
              onClick={() => handleOpenScan()}
              className="min-w-[160px] px-10 py-2 bg-blue-500 rounded-lg text-white font-bold"
            >
              OK
            </button>
          </div>
        )}

        {state === "checkout" && (
          <div className="flex flex-col justify-center items-center pb-20">
            <div className="text-white font-semibold mb-2">CHECK OUT</div>
            <div className="text-white font-bold mb-10 text-x uppercase">
              {query.get("event")}
            </div>

            <div className="text-white font-bold mb-10 text-xl bg-red-500 px-10 py-12 rounded-md">
              Haven't Check In Yet
            </div>

            <div className="text-white font-bold mb-0 text-lg text-center">
              {user?.user_type}
            </div>
            <div className="text-white font-bold mb-6 text-2xl text-center">
              {user?.name}
            </div>

            <button
              onClick={() => handleOpenScan()}
              className="min-w-[160px] px-10 py-2 bg-blue-500 rounded-lg text-white font-bold"
            >
              OK
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default PageCheckout;
