import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import PageHome from "./pages/home";
import PageCheckin from "./pages/checkin";
import PageRedeem from "./pages/redeem";
import PageCheckout from "./pages/checkout";
import PageLogin from "./pages/login";
import PageEvent from "./pages/event";

function App() {
  return (
    <Routes>
      <Route path="/" element={<PageLogin />} />
      <Route path="/event" element={<PageEvent />} />
      <Route path="/home" element={<PageHome />} />
      <Route path="/checkin" element={<PageCheckin />} />
      <Route path="/checkout" element={<PageCheckout />} />
      <Route path="/redeem" element={<PageRedeem />} />
    </Routes>
  );
}

export default App;
